import { useEffect, useState, lazy, Suspense } from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { isAfter } from 'date-fns';
import { Navigation, paths, generateUrl } from './lib';
import { useDispatch, setAfterLoginRoute } from './state';
import { useGetUsersData, useIsMobile } from './hooks';
import { MainLayout } from './components/layout/MainLayout';
import { PageLoader } from './components';

const MobileHomePage = lazy(() => import('./pages/MobileHomePage'));
const MobileCameraUploadPage = lazy(
  () => import('./components/common/modals/MobileCameraUploadPage'),
);
const CustomerDocuments = lazy(
  () => import('./pages/customers/CustomerDocuments'),
);
const CustomerResellerBills = lazy(
  () => import('./pages/customers/CustomerResellerBills'),
);
const CustomerAccounting = lazy(
  () => import('./pages/customers/CustomerAccounting'),
);
const ReportsPage = lazy(() => import('./pages/ReportsPage'));
const DashboardPage = lazy(() => import('./pages/DashboardPage'));
const OrderProcessingDashboard = lazy(
  () => import('./pages/dashboards/orderProcessing/OrderProcessingDashboard'),
);
const ProfitAndLossDashboard = lazy(
  () => import('./pages/dashboards/profitAndLoss/ProfitAndLossDashboard'),
);
const ShippingDashboard = lazy(
  () => import('./pages/dashboards/shipping/ShippingDashboardPageContainer'),
);
const AuthPage = lazy(() => import('./pages/Auth'));
const CreateCompanyUserAccount = lazy(
  () => import('./pages/users/CreateCompanyUserAccount'),
);
const CompanyUserProfile = lazy(
  () => import('./pages/users/CompanyUserProfile'),
);
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const ProcessOrderHome = lazy(
  () => import('./pages/processOrder/ProcessOrderHome'),
);
const ScanOrder = lazy(() => import('./pages/processOrder/ScanOrder'));
const PackingMode = lazy(() => import('./pages/processOrder/PackingMode'));
const FreightPackingMode = lazy(
  () => import('./pages/processOrder/FreightPackingMode'),
);
const ManagePalletsHome = lazy(
  () => import('./pages/processOrder/ManagePalletsHome'),
);
const ManagePalletsPagesContainer = lazy(
  () =>
    import('./pages/processOrder/managePallets/ManagePalletsPagesContainer'),
);
const UnloadAppointmentsContainer = lazy(
  () => import('./pages/unloadAppointment/UnloadAppointmentsContainer'),
);
const CompleteAppointmentMobileToggle = lazy(
  () => import('./pages/CompleteAppointmentMobileToggle'),
);
const VerifyAppointmentsContainer = lazy(
  () =>
    import(
      './pages/appointments/verifyAppointment/VerifyAppointmentsContainer'
    ),
);
const AssignAppointmentReceiptsMobileContainer = lazy(
  () =>
    import(
      './pages/appointments/assignAppointment/AssignAppointmentReceiptsMobileContainer'
    ),
);
const OrdersPage = lazy(() => import('./pages/OrdersPage'));
const BatchOrdersPage = lazy(() => import('./pages/BatchOrdersPage'));
const BatchesPage = lazy(() => import('./pages/BatchesPage'));
const BatchPagesContainer = lazy(
  () => import('./pages/batchComponents/BatchPagesContainer'),
);
const OrderPagesContainer = lazy(
  () => import('./pages/orderComponents/OrderPagesContainer'),
);
const InventoryStockPage = lazy(() => import('./pages/InventoryStockPage'));
const LocationsPage = lazy(() => import('./pages/LocationsPage'));
const CreateReturn = lazy(() => import('./pages/orderComponents/CreateReturn'));
const ShippingHistoryPage = lazy(() => import('./pages/ShippingHistoryPage'));
const PrintLabelsPage = lazy(() => import('./pages/PrintLabelsPage'));
const TruckingJobsPage = lazy(() => import('./pages/TruckingJobsPage'));
const CustomerQuoteView = lazy(
  () => import('./pages/truckingJobsComponents/CustomerQuoteView'),
);

const TruckingJobRatesPage = lazy(
  () => import('./pages/truckingJobsComponents/TruckingJobRatesPage'),
);
const DrayagePage = lazy(() => import('./pages/DrayagePage'));
const DrayagePagesContainer = lazy(
  () => import('./pages/drayageJobComponents/DrayagePagesContainer'),
);
const DrayageJobCarrierConfirmView = lazy(
  () => import('./pages/drayageJobComponents/DrayageJobCarrierConfirmView'),
);
const GetQuoteContainer = lazy(
  () => import('./pages/quotes/GetQuoteContainer'),
);
const PurchasedLabelsPage = lazy(
  () => import('./pages/quotes/PurchasedLabelsPage'),
);
const PurchaseLabelsContainer = lazy(
  () => import('./pages/quotes/PurchaseLabelsContainer'),
);
const PurchasedLabelPagesContainer = lazy(
  () => import('./pages/quotes/PurchasedLabelPagesContainer'),
);
const CustomersPage = lazy(() => import('./pages/customers/CustomersPage'));
const CustomerPagesContainer = lazy(
  () => import('./pages/customers/CustomerPagesContainer'),
);
const EditCustomerProfile = lazy(
  () => import('./pages/customers/EditCustomerProfile'),
);
const AuthorizeAmazonLandingPage = lazy(
  () => import('./pages/customers/AuthorizeAmazonLandingPage'),
);
const BillPage = lazy(() => import('./pages/billing/BillPage'));
const BillDetailsContainer = lazy(
  () => import('./pages/billing/BillDetailsContainer'),
);
const AccountsReceivablePage = lazy(
  () => import('./pages/AccountsReceivablePage'),
);
const AccountPayablePage = lazy(
  () => import('./pages/billing/AccountPayablePage'),
);
const AccountPayableDetailsPage = lazy(
  () => import('./pages/billing/AccountPayableDetailsPage'),
);
const CarriersAndAccountsPage = lazy(
  () => import('./pages/CarriersAndAccountsPage'),
);
const AddEditCarrierAccountPage = lazy(
  () => import('./pages/AddEditCarrierAccountPage'),
);
const AddNewQuotePage = lazy(
  () => import('./pages/truckingJobsComponents/AddNewQuotePage'),
);
const TruckingJobsPagesContainer = lazy(
  () => import('./pages/truckingJobsComponents/TruckingJobsPagesContainer'),
);
const ManageCarrierAccountServicesPage = lazy(
  () => import('./pages/ManageCarrierAccountServicesPage'),
);
const MaterialPage = lazy(() => import('./pages/vendorsMaterial/MaterialPage'));
const AddEditProducts = lazy(
  () => import('./pages/vendorsMaterial/AddEditProducts'),
);
const AddEditVendor = lazy(
  () => import('./pages/vendorsMaterial/AddEditVendor'),
);
const UsersPage = lazy(() => import('./pages/users/UsersPage'));
const AddEditUser = lazy(() => import('./pages/users/AddEditUser'));
const SettingsPage = lazy(() => import('./pages/settings/SettingsPage'));
const TerminalsPage = lazy(() => import('./pages/TerminalsPage'));
const AddEditDrayageJob = lazy(
  () => import('./pages/drayageJobComponents/AddEditDrayageJob'),
);
const SystemServices = lazy(() => import('./pages/services/SystemServices'));
const FulfilledServices = lazy(() => import('./pages/FulfilledServices'));
const AddEditFulfilledService = lazy(
  () => import('./pages/services/AddEditFulfilledService'),
);
const FulfilledServicesPagesContainer = lazy(
  () => import('./pages/services/FulfilledServicesPagesContainer'),
);
const WarehousesPage = lazy(() => import('./pages/warehouses/WarehousesPage'));
const WarehousePagesContainer = lazy(
  () => import('./pages/warehouses/WarehousePagesContainer'),
);
const ReceiptsPage = lazy(() => import('./pages/ReceiptsPage'));
const AddReceipt = lazy(() => import('./pages/receipt/AddReceipt'));
const ReceiptPagesContainer = lazy(
  () => import('./pages/receipt/ReceiptPagesContainer'),
);
const EditCustomerItemSettings = lazy(
  () => import('./pages/customers/EditCustomerItemSettings'),
);
const CheckInSignInPage = lazy(
  () => import('./pages/appointmentCheckIn/CheckInSignInPage'),
);
const CheckInForm = lazy(
  () => import('./pages/appointmentCheckIn/CheckInForm'),
);
const InternalUserCheckInDriver = lazy(
  () => import('./pages/appointmentCheckIn/InternalUserCheckInDriver'),
);
const DriverCheckInsPage = lazy(() => import('./pages/DriverCheckInsPage'));
const AppointmentsPage = lazy(() => import('./pages/AppointmentsPage'));
const AddAppointmentPage = lazy(
  () => import('./pages/appointments/AddEditAppointment'),
);
const AppointmentPagesContainer = lazy(
  () => import('./pages/appointments/AppointmentPagesContainer'),
);
const PrepReceiptHome = lazy(
  () => import('./pages/receipt/prepReceipt/PrepReceiptHome'),
);
const ValidateReceiptHome = lazy(
  () => import('./pages/receipt/validateReceipt/ValidateReceiptHome'),
);

export default function Routes() {
  return (
    <Router history={Navigation.history}>
      <Suspense fallback={<PageLoader appLoading />}>
        <Switch>
          <Route
            exact
            path={generateUrl(paths.LOGIN)}
            render={(props) => <AuthPage {...props} />}
          />
          <Route
            exact
            path={generateUrl(paths.FORGOT_PASSWORD)}
            render={(props) => <AuthPage {...props} />}
          />
          <Route
            exact
            path={generateUrl(paths.MF_AUTH)}
            render={(props) => <AuthPage {...props} />}
          />
          <Route
            exact
            path={generateUrl(paths.RESET_PASSWORD)}
            render={(props) => <AuthPage {...props} />}
          />
          <Route
            exact
            path={generateUrl(paths.CREATE_COMPANY_USER_ACCOUNT)}
            render={(props) => <CreateCompanyUserAccount {...props} />}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.MOBILE_HOME)}
            component={MobileHomePage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.MOBILE_PHOTOS)}
            component={MobileCameraUploadPage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.COMPANY_USER_PROFILE)}
            component={CompanyUserProfile}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.MAIN_KPI_DASHBOARD)}
            component={DashboardPage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.ORDER_FULFILLMENT_DASHBOARD)}
            component={OrderProcessingDashboard}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.P_L_DASHBOARD)}
            component={ProfitAndLossDashboard}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.SHIPPING_DASHBOARD)}
            component={ShippingDashboard}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.SYSTEM_SERVICES)}
            component={SystemServices}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.FULFILLED_SERVICES)}
            component={FulfilledServices}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.FULFILLED_SERVICE_PAGES)}
            component={FulfilledServicesPagesContainer}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.ADD_FULFILLED_SERVICE)}
            component={AddEditFulfilledService}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.EDIT_FULFILLED_SERVICE)}
            component={AddEditFulfilledService}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.ADMIN_REPORTS)}
            component={ReportsPage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.SHARED_REPORTS)}
            component={ReportsPage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.PROCESS_ORDER)}
            component={ProcessOrderHome}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.SCAN_ORDER)}
            component={ScanOrder}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.PACKING_MODE)}
            component={PackingMode}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.FREIGHT_PACKING_MODE)}
            component={FreightPackingMode}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.MANAGE_PALLETS)}
            component={ManagePalletsHome}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.MANAGE_PALLETS_PAGES)}
            component={ManagePalletsPagesContainer}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.UNLOAD_APPOINTMENT)}
            component={UnloadAppointmentsContainer}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.COMPLETE_APPOINTMENT_TOGGLE)}
            component={CompleteAppointmentMobileToggle}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.VERIFY_APPOINTMENT)}
            component={VerifyAppointmentsContainer}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.ASSIGN_APPOINTMENT_RECEIPTS)}
            component={AssignAppointmentReceiptsMobileContainer}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.ORDERS)}
            component={OrdersPage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.ADD_ORDER_APPOINTMENT)}
            component={OrdersPage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.ORDER_PAGES)}
            component={OrderPagesContainer}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.PROCESS_RETURN)}
            component={CreateReturn}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.BATCH_ORDERS)}
            component={BatchOrdersPage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.BATCH_LIST)}
            component={BatchesPage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.BATCH_PAGES)}
            component={BatchPagesContainer}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.STOCK)}
            component={InventoryStockPage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.LOCATIONS)}
            component={LocationsPage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.SHIPPING_HISTORY)}
            component={ShippingHistoryPage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.PRINT_LABEL)}
            component={PrintLabelsPage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.ADD_NEW_TRUCKING_JOB)}
            component={AddNewQuotePage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.EDIT_TRUCKING_JOB)}
            component={AddNewQuotePage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.TRUCKING_JOBS)}
            component={TruckingJobsPage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.ADD_TRUCKING_JOB_APPOINTMENT)}
            component={TruckingJobsPage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.EDIT_QUOTE)}
            component={AddNewQuotePage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.TRUCKING_JOB_RATES)}
            component={TruckingJobRatesPage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.DRAYAGE_JOBS)}
            component={DrayagePage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.ADD_DRAYAGE_JOB_APPOINTMENT)}
            component={DrayagePage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.EDIT_DRAYAGE_DELIVERY_ORDER_JOBS)}
            component={AddEditDrayageJob}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.ADD_NEW_DRAYAGE_JOB)}
            component={AddEditDrayageJob}
            layout={MainLayout}
          />
          <Route
            exact
            path={generateUrl(paths.CARRIER_CONFIRM_DRAYAGE_DELIVERY_ORDER_JOB)}
            render={(props) => <DrayageJobCarrierConfirmView {...props} />}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.DRAYAGE_JOB_PAGES)}
            component={DrayagePagesContainer}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.GET_SP_ESTIMATE)}
            component={GetQuoteContainer}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.PURCHASED_LABEL)}
            component={PurchasedLabelsPage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.PURCHASE_LABEL)}
            component={PurchaseLabelsContainer}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.PURCHASED_LABEL_DETAILS)}
            component={PurchasedLabelPagesContainer}
            layout={MainLayout}
          />
          <Route
            exact
            path={generateUrl(paths.CUSTOMER_VIEW_QUOTE)}
            render={(props) => <CustomerQuoteView {...props} />}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.CUSTOMERS)}
            component={CustomersPage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.EDIT_CUSTOMER_PROFILE)}
            component={EditCustomerProfile}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.CUSTOMER_AUTHORIZE_AMAZON_LANDING_PAGE)}
            component={AuthorizeAmazonLandingPage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.CUSTOMER_INVOICE_DETAILS)}
            component={CustomerPagesContainer}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.CUSTOMER_PAGES)}
            component={CustomerPagesContainer}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.EDIT_CUSTOMER_ITEM)}
            component={EditCustomerItemSettings}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.ACCOUNTING)}
            component={CustomerAccounting}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.RESELLER_BILLS)}
            component={CustomerResellerBills}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.DOCUMENTS)}
            component={CustomerDocuments}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.BILLS)}
            component={BillPage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.BILL_DETAILS_PAGE)}
            component={BillDetailsContainer}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.ACCOUNT_RECEIVABLE)}
            component={AccountsReceivablePage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.ACCOUNT_PAYABLE)}
            component={AccountPayablePage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.ACCOUNT_PAYABLE_DETAILS_PAGE)}
            component={AccountPayableDetailsPage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.CARRIERS_ACCOUNTS)}
            component={CarriersAndAccountsPage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.EDIT_CARRIER_ACCOUNT)}
            component={AddEditCarrierAccountPage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.ADD_CARRIER_ACCOUNT)}
            component={AddEditCarrierAccountPage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.TRUCKING_JOB_PAGES)}
            component={TruckingJobsPagesContainer}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.MANAGE_CARRIER_ACCOUNT_SERVICES)}
            component={ManageCarrierAccountServicesPage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.MATERIALS)}
            component={MaterialPage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.ADD_PRODUCTS)}
            component={AddEditProducts}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.EDIT_PRODUCTS)}
            component={AddEditProducts}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.ADD_VENDORS)}
            component={AddEditVendor}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.EDIT_VENDORS)}
            component={AddEditVendor}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.ADD_USERS)}
            component={AddEditUser}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.USERS)}
            component={UsersPage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.EDIT_USERS)}
            component={AddEditUser}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.SETTINGS)}
            component={SettingsPage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.TERMINALS)}
            component={TerminalsPage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.WAREHOUSES)}
            component={WarehousesPage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.WAREHOUSE_PAGES)}
            component={WarehousePagesContainer}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.RECEIPTS)}
            component={ReceiptsPage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.ADD_RECEIPT_APPOINTMENT)}
            component={ReceiptsPage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.RECEIPT_PAGES)}
            component={ReceiptPagesContainer}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.ADD_RECEIPT)}
            component={AddReceipt}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.RECEIPT_PURCHASE_LABELS)}
            component={PurchaseLabelsContainer}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.PREP_RECEIPTS)}
            component={PrepReceiptHome}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.VALIDATE_RECEIPTS)}
            component={ValidateReceiptHome}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.VALIDATE_RECEIPT)}
            component={ValidateReceiptHome}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.RECEIPT_TRUCKING_JOB)}
            component={AddNewQuotePage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.RECEIPT_DRAYAGE_JOB)}
            component={AddEditDrayageJob}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.DRIVER_CHECK_IN)}
            component={DriverCheckInsPage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.LINK_DRIVER_CHECK_IN_APPOINTMENT)}
            component={DriverCheckInsPage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.LINK_APPOINTMENTS_CHECK_IN)}
            component={AppointmentsPage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.APPOINTMENTS)}
            component={AppointmentsPage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.ADD_APPOINTMENT)}
            component={AddAppointmentPage}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.APPOINTMENT_PAGES)}
            component={AppointmentPagesContainer}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.TRACK_TIME)}
            component={BlankComponent}
            layout={MainLayout}
          />
          <Route
            exact
            path={generateUrl(paths.APPOINTMENT_CHECK_IN)}
            render={(props) => <CheckInSignInPage {...props} />}
          />
          <AnonRoute
            exact
            path={generateUrl(paths.APPOINTMENT_CHECK_IN_FORM)}
            component={CheckInForm}
            layout={MainLayout}
          />
          <AuthRoute
            exact
            path={generateUrl(paths.APPOINTMENT_CHECK_IN_FORM_INTERNAL)}
            component={InternalUserCheckInDriver}
            layout={MainLayout}
          />
          <Route exact path='/'>
            <RootRedirect />
          </Route>
          <Route exact path={generateUrl(paths.NOT_FOUND)}>
            <NotFoundPage />
          </Route>
          <Route>
            <Redirect to={generateUrl(paths.NOT_FOUND)} />
          </Route>
        </Switch>
      </Suspense>
    </Router>
  );
}

function RootRedirect(props) {
  const isMobile = useIsMobile(true);
  const {
    isCustomer,
    isScanner,
    isScannerProcessor,
    isDriverCheckInMode,
    isManageAppointmentUser,
    isManager,
  } = useGetUsersData();
  let path = paths.PROCESS_ORDER;

  switch (true) {
    case isDriverCheckInMode:
      path = paths.APPOINTMENT_CHECK_IN;
      break;
    case isCustomer:
      path = paths.ORDERS;
      break;
    case isScannerProcessor:
      if (isMobile) {
        path = paths.MOBILE_HOME;
      }
      break;
    case isScanner:
      if (isMobile) {
        path = paths.MOBILE_HOME;
      } else {
        path = paths.MANAGE_PALLETS;
      }
      break;
    case isManageAppointmentUser:
      path = paths.APPOINTMENTS;
      break;
    case isManager:
      if (isMobile) {
        path = paths.MOBILE_HOME;
      } else {
        path = paths.APPOINTMENTS;
      }
      break;
    default:
      break;
  }
  return <Redirect to={generateUrl(path)} {...props} />;
}

/** @param {import("react-router-dom").RouteProps} */
function AnonRoute({ component: Component, layout: Layout, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Suspense fallback={<PageLoader appLoading />}>
          <Layout {...props}>
            <Component {...props} />
          </Layout>
        </Suspense>
      )}
    />
  );
}

/** @param {import("react-router-dom").RouteProps} */
function AuthRoute({ component: Component, layout: Layout, ...rest }) {
  const dispatch = useDispatch();
  const { path, computedMatch: { params } = {} } = rest;

  const [canRedirect, setCanRedirect] = useState(false);

  const {
    token,
    expiration,
    PermissionClaim,
    isAdmin,
    isCustomer,
    isProcessor,
    isCustomerService,
    isTruckingSalesPerson,
    isScanner,
    isScannerProcessor,
    isManager,
    isManageAppointmentUser,
    customerId,
  } = useGetUsersData();
  const hasPermission = checkPermissions({
    PermissionClaim,
    path,
    params,
    isAdmin,
    isCustomer,
    isProcessor,
    isCustomerService,
    isTruckingSalesPerson,
    isScanner,
    isScannerProcessor,
    isManageAppointmentUser,
    isManager,
    customerId,
  });

  const hasInvalidToken = !token || isAfter(new Date(), new Date(expiration));

  useEffect(() => {
    if (!hasInvalidToken) {
      setCanRedirect(false);
    } else {
      const { pathname, search } = window.location;
      if (
        hasInvalidToken &&
        pathname !== generateUrl(paths.LOGIN) &&
        pathname !== generateUrl(paths.PROCESS_ORDER)
      ) {
        dispatch(
          setAfterLoginRoute({
            redirectTo: pathname + search,
          }),
        );
      }
      setCanRedirect(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasInvalidToken]);

  // make sure that we set the redirect url before navigating
  if (hasInvalidToken && !canRedirect) return null;
  if (hasInvalidToken) return <Redirect to={generateUrl(paths.LOGIN)} />;
  if (!hasPermission) return <Redirect to={generateUrl(paths.NOT_FOUND)} />;

  return (
    <Route
      {...rest}
      render={(props) => (
        <Suspense fallback={<PageLoader appLoading />}>
          <Layout {...props}>
            <Component {...props} />
          </Layout>
        </Suspense>
      )}
    />
  );
}

// Need this for the track-time route because the layout will handle rendering the component
// so we just need the router not to redirect to not-found
const BlankComponent = () => null;

function checkPermissions({
  path,
  params,
  isAdmin,
  isProcessor,
  isCustomerService,
  isTruckingSalesPerson,
  isCustomer,
  isScanner,
  isScannerProcessor,
  isManageAppointmentUser,
  isManager,
  customerId,
}) {
  // define the route as the key and an array of user types with permission to this route. Admin will have permission to all paths.
  // ie. '/admin/account/create-company': [permissionTypes.PROCESSOR],
  const userTypesPermissions = {
    '/process-order': [
      permissionTypes.PROCESSOR,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    [generateUrl(paths.MAIN_KPI_DASHBOARD)]: [permissionTypes.CUSTOMER_SERVICE],
    [generateUrl(paths.ORDER_FULFILLMENT_DASHBOARD)]: [
      permissionTypes.PROCESSOR,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
      permissionTypes.CUSTOMER,
    ],
    [generateUrl(paths.P_L_DASHBOARD)]: [],
    [generateUrl(paths.SHIPPING_DASHBOARD)]: [permissionTypes.CUSTOMER],
    [generateUrl(paths.ADMIN_REPORTS)]: [permissionTypes.CUSTOMER_SERVICE],
    [generateUrl(paths.SHARED_REPORTS)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
      permissionTypes.CUSTOMER,
    ],
    [generateUrl(paths.SYSTEM_SERVICES)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    [generateUrl(paths.FULFILLED_SERVICES)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
      permissionTypes.CUSTOMER,
    ],
    [generateUrl(paths.ADD_FULFILLED_SERVICE)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    [generateUrl(paths.EDIT_FULFILLED_SERVICE)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    [generateUrl(paths.FULFILLED_SERVICE_PAGES)]: [
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    '/process-order/scan-order': [
      permissionTypes.PROCESSOR,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    '/process-order/:ORDER_ID/packing-mode': [
      permissionTypes.PROCESSOR,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    [generateUrl(paths.FREIGHT_PACKING_MODE)]: [
      permissionTypes.PROCESSOR,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    [generateUrl(paths.MANAGE_PALLETS)]: [
      permissionTypes.SCANNER,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
      permissionTypes.MANAGER,
    ],
    [generateUrl(paths.MANAGE_PALLETS_PAGES)]: [
      permissionTypes.SCANNER,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
      permissionTypes.MANAGER,
    ],
    [generateUrl(paths.UNLOAD_APPOINTMENT)]: [
      permissionTypes.SCANNER,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
      permissionTypes.MANAGER,
    ],
    [generateUrl(paths.VERIFY_APPOINTMENT)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
      permissionTypes.MANAGER,
    ],
    [generateUrl(paths.COMPLETE_APPOINTMENT_TOGGLE)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
      permissionTypes.MANAGER,
    ],
    [generateUrl(paths.ASSIGN_APPOINTMENT_RECEIPTS)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
      permissionTypes.MANAGER,
    ],
    '/orders': [
      permissionTypes.PROCESSOR,
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    '/order/:ORDER_ID/:NESTED_PAGE': [
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    [generateUrl(paths.PROCESS_RETURN)]: [
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    '/batch-orders': [
      permissionTypes.PROCESSOR,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    '/batches': [
      permissionTypes.PROCESSOR,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    '/batches/:BATCH_ID/:NESTED_PAGE': [
      permissionTypes.PROCESSOR,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    '/trucking-jobs/add': [
      permissionTypes.TRUCKING_SALES,
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
    ],
    [generateUrl(paths.TRUCKING_JOBS)]: [
      permissionTypes.TRUCKING_SALES,
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
    ],
    [generateUrl(paths.EDIT_TRUCKING_JOB)]: [
      permissionTypes.TRUCKING_SALES,
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
    ],
    [generateUrl(paths.TRUCKING_JOB_PAGES)]: [
      permissionTypes.TRUCKING_SALES,
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
    ],
    [generateUrl(paths.TRUCKING_JOB_RATES)]: [
      permissionTypes.TRUCKING_SALES,
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
    ],
    [generateUrl(paths.TRUCKING_LOAD_DETAILS)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    [generateUrl(paths.DRAYAGE_JOBS)]: [
      permissionTypes.TRUCKING_SALES,
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
    ],
    [generateUrl(paths.ADD_NEW_DRAYAGE_JOB)]: [
      permissionTypes.TRUCKING_SALES,
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
    ],
    [generateUrl(paths.EDIT_DRAYAGE_DELIVERY_ORDER_JOBS)]: [
      permissionTypes.TRUCKING_SALES,
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
    ],
    [generateUrl(paths.DRAYAGE_JOB_PAGES)]: [
      permissionTypes.TRUCKING_SALES,
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
    ],
    [generateUrl(paths.GET_SP_ESTIMATE)]: [
      permissionTypes.PROCESSOR,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
      permissionTypes.CUSTOMER,
    ],
    [generateUrl(paths.PURCHASED_LABEL)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
      permissionTypes.CUSTOMER,
    ],
    [generateUrl(paths.PURCHASE_LABEL)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
      permissionTypes.CUSTOMER,
    ],
    [generateUrl(paths.PURCHASED_LABEL_DETAILS)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
      permissionTypes.CUSTOMER,
    ],
    '/customers': [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    '/customer/:CUSTOMER_ID/:NESTED_PAGE': [
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    [generateUrl(paths.CUSTOMER_AUTHORIZE_AMAZON_LANDING_PAGE)]: [
      permissionTypes.CUSTOMER,
    ],
    [generateUrl(paths.CUSTOMER_INVOICE_DETAILS)]: [
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    '/billing': [permissionTypes.CUSTOMER_SERVICE],
    [generateUrl(paths.ACCOUNT_RECEIVABLE)]: [permissionTypes.CUSTOMER_SERVICE],
    [generateUrl(paths.ACCOUNT_PAYABLE)]: [permissionTypes.CUSTOMER_SERVICE],
    [generateUrl(paths.ACCOUNT_PAYABLE_DETAILS_PAGE)]: [
      permissionTypes.CUSTOMER_SERVICE,
    ],
    '/carriers-accounts': [
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    '/carriers-accounts/edit/:CARRIER_ID': [
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    '/carriers-accounts/add': [
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    '/carriers-accounts/services': [
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    '/materials/:NESTED_PAGE': [permissionTypes.CUSTOMER_SERVICE],
    '/materials/products/edit/:PRODUCT_ID': [],
    '/materials/vendors/edit/:VENDOR_ID': [],
    '/materials/vendors/add': [],
    '/materials/products/add': [],
    '/users/:NESTED_PAGE': [permissionTypes.CUSTOMER_SERVICE],
    '/users/:NESTED_PAGE/edit/:USER_ID': [],
    '/users/add': [],
    '/settings/:NESTED_PAGE': [permissionTypes.CUSTOMER_SERVICE],
    [generateUrl(paths.TERMINALS)]: [
      permissionTypes.TRUCKING_SALES,
      permissionTypes.CUSTOMER_SERVICE,
    ],
    [generateUrl(paths.EDIT_CUSTOMER_ITEM)]: [permissionTypes.CUSTOMER_SERVICE],
    [generateUrl(paths.RESELLER_BILLS)]: [
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
    ],
    [generateUrl(paths.ACCOUNTING)]: [
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
    ],
    [generateUrl(paths.DOCUMENTS)]: [
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
    ],
    [generateUrl(paths.WAREHOUSES)]: [permissionTypes.CUSTOMER_SERVICE],
    [generateUrl(paths.WAREHOUSE_PAGES)]: [],
    [generateUrl(paths.TRACK_TIME)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
      permissionTypes.PROCESSOR,
      permissionTypes.SCANNER,
      permissionTypes.MANAGER,
    ],
    [generateUrl(paths.RECEIPTS)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.CUSTOMER,
      permissionTypes.MANAGER,
    ],
    [generateUrl(paths.RECEIPT_PAGES)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.CUSTOMER,
      permissionTypes.MANAGER,
    ],
    [generateUrl(paths.ADD_RECEIPT)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.CUSTOMER,
    ],
    [generateUrl(paths.RECEIPT_PURCHASE_LABELS)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.CUSTOMER,
    ],
    [generateUrl(paths.RECEIPT_TRUCKING_JOB)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.CUSTOMER,
    ],
    [generateUrl(paths.RECEIPT_DRAYAGE_JOB)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.CUSTOMER,
    ],
    [generateUrl(paths.PREP_RECEIPTS)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.MANAGER,
      permissionTypes.SCANNER,
    ],
    [generateUrl(paths.VALIDATE_RECEIPT)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.MANAGER,
    ],
    [generateUrl(paths.VALIDATE_RECEIPTS)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.MANAGER,
    ],
    [generateUrl(paths.DRIVER_CHECK_IN)]: [permissionTypes.CUSTOMER_SERVICE],
    [generateUrl(paths.APPOINTMENT_CHECK_IN_FORM_INTERNAL)]: [
      permissionTypes.CUSTOMER_SERVICE,
    ],
    [generateUrl(paths.LINK_DRIVER_CHECK_IN_APPOINTMENT)]: [
      permissionTypes.CUSTOMER_SERVICE,
    ],
    [generateUrl(paths.LINK_APPOINTMENTS_CHECK_IN)]: [
      permissionTypes.CUSTOMER_SERVICE,
    ],
    [generateUrl(paths.APPOINTMENTS)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.CUSTOMER,
      permissionTypes.MANAGE_APPOINTMENTS,
      permissionTypes.MANAGER,
    ],
    [generateUrl(paths.ADD_APPOINTMENT)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.CUSTOMER,
      permissionTypes.MANAGE_APPOINTMENTS,
    ],
    [generateUrl(paths.APPOINTMENT_PAGES)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.CUSTOMER,
      permissionTypes.MANAGE_APPOINTMENTS,
      permissionTypes.MANAGER,
    ],
    [generateUrl(paths.ADD_ORDER_APPOINTMENT)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.MANAGE_APPOINTMENTS,
    ],
    [generateUrl(paths.ADD_TRUCKING_JOB_APPOINTMENT)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.MANAGE_APPOINTMENTS,
    ],
    [generateUrl(paths.ADD_DRAYAGE_JOB_APPOINTMENT)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.MANAGE_APPOINTMENTS,
    ],
    [generateUrl(paths.ADD_RECEIPT_APPOINTMENT)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.MANAGE_APPOINTMENTS,
    ],
    [generateUrl(paths.COMPANY_USER_PROFILE)]: [
      permissionTypes.MANAGE_APPOINTMENTS,
    ],
    [generateUrl(paths.STOCK)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.CUSTOMER,
      permissionTypes.MANAGER,
    ],
    [generateUrl(paths.LOCATIONS)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.CUSTOMER,
      permissionTypes.MANAGER,
    ],
  };

  if (isCustomer && path === '/customer/:CUSTOMER_ID/:NESTED_PAGE') {
    const { CUSTOMER_ID } = params;
    return parseInt(CUSTOMER_ID) === customerId;
  }

  return (
    isAdmin ||
    !userTypesPermissions[path] ||
    userTypesPermissions[path].some((userType) => {
      if (isScannerProcessor) {
        return (
          userType === permissionTypes.SCANNER ||
          userType === permissionTypes.PROCESSOR
        );
      } else if (isProcessor) {
        return userType === permissionTypes.PROCESSOR;
      } else if (isCustomerService) {
        return userType === permissionTypes.CUSTOMER_SERVICE;
      } else if (isTruckingSalesPerson) {
        return userType === permissionTypes.TRUCKING_SALES;
      } else if (isCustomer) {
        return userType === permissionTypes.CUSTOMER;
      } else if (isScanner) {
        return userType === permissionTypes.SCANNER;
      } else if (isManageAppointmentUser) {
        return userType === permissionTypes.MANAGE_APPOINTMENTS;
      } else if (isManager) {
        return userType === permissionTypes.MANAGER;
      }
      return false;
    })
  );
}

const permissionTypes = {
  ADMIN: 'isAdmin',
  CUSTOMER_SERVICE: 'isCustomerService',
  TRUCKING_SALES: 'isTruckingSalesPerson',
  PROCESSOR: 'isProcessor',
  SCANNER: 'isScanner',
  CUSTOMER: 'isCustomer',
  MANAGE_APPOINTMENTS: 'isManageAppointmentUser',
  DRIVER_CHECK_IN_MODE: 'isDriverCheckInMode',
  MANAGER: 'isManager',
};
